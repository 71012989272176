import { chartColorData } from '@lemontree-ai/lemontree-admin-common-front/constants/chartColorData'
import { hexToRgb } from '@lemontree-ai/lemontree-admin-common-front/utils/colorUtils';
import { mapGetters } from 'vuex';

const ChartMixin = {
  data(){
    return {
      chartDataSync:{},
      dafaltData: { responsive: true, maintainAspectRatio: false, legend:{}, scales:{} },
    }
  },
  computed:{
    ...mapGetters('commonGnb', [ 'getIsActiveGnb']),
  },
  watch:{
    getIsActiveGnb(){
      this.resizeChart();
    },
    'chartData.labels':{
      deep:true,
      handler(){
        this.chartDataSync = JSON.parse(JSON.stringify(this.chartData));
        this.setColor();
        this.init();
      }
    }
  },
  mounted(){
    this.chartDataSync = JSON.parse(JSON.stringify(this.chartData));
    this.setColor();
    if(this.title){
      this.dafaltData.title = { display: true, text: this.title }
    }
    let scales = { xAxes: [], yAxes:[] }
    if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
      this.dafaltData.legend = {
        labels: { fontColor: "white", fontSize: 14 }
      };
      this.dafaltData.scales = {
        xAxes: [{ ticks:{ fontColor:"#BEBEBE", fontSize:14, stepSize:1 } }],
        yAxes: [{ ticks:{ fontColor:"#BEBEBE", fontSize:14, stepSize:1 } }],
      };
    }else{
      
    }
    if(this.chartId === 'bar-chart' && this.isStacked){
      this.dafaltData.scales = {
        xAxes: [{ stacked: true, gridLines: { display: false } }],
        yAxes: [{ stacked: true, ticks: { beginAtZero: true, }, type: 'linear' }]
      };
    }
    this.init();
  },
  methods:{
    setColor(){
      const isPieChart = this.chartId === 'pie-chart';
      const isLineChart = this.chartId === 'line-chart';
      const isRadarChart = this.chartId === 'radar-chart';
      // pie
      if(isPieChart){
        this.chartDataSync.datasets[0].backgroundColor = this.chartDataSync.datasets[0].backgroundColor.map(item => {
          const bgColor = chartColorData[item.color];
          if(item.isGradient){
            return this.getGradient(bgColor);
          }else{
            return bgColor;
          }
        });
      }else{
        this.chartDataSync.datasets.forEach(item => {
          const bgColor = chartColorData[item.backgroundColor];
          const borderColor = chartColorData[item.borderColor];
          const isAreaChart = isLineChart && item.backgroundColor != 'transparent';
          // Area
          if(isAreaChart){
            item.backgroundColor = this.getGradient(bgColor);
            item.borderWidth = 1;
            item.pointBackgroundColor = bgColor;
            item.pointHoverBackgroundColor = '#fff';
            item.borderColor = bgColor;
            item.pointBorderColor = '#fff';
            item.pointHoverBorderColor = bgColor;
          // Line
          }else if(isLineChart){
            item.borderWidth = 2;
            item.borderColor = hexToRgb(borderColor,0.6);
            item.pointBackgroundColor = borderColor;
            if(item.backgroundColor != 'transparent'){
              item.backgroundColor = this.getGradient(bgColor);
            }
          // Radar
          }else if(isRadarChart){
            item.backgroundColor = hexToRgb(bgColor,0.2);
            item.pointBackgroundColor = bgColor;
            item.pointHoverBackgroundColor = '#fff';
            item.borderColor = hexToRgb(bgColor,0.6);
            item.pointBorderColor = '#fff';
            item.pointHoverBorderColor = bgColor;

          }else{
            item.backgroundColor = bgColor;
          }
        });
      }
    },
    getGradient(bgColor){
      let gradient = null;
      gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
      gradient.addColorStop(0, hexToRgb(bgColor,0.5));
      gradient.addColorStop(0.5, hexToRgb(bgColor,0.25));
      gradient.addColorStop(1, hexToRgb(bgColor,0));
      return gradient;
    },
    init(){
      this.renderChart( this.chartDataSync, this.dafaltData);
    },
    resizeChart(){
      this.init()
    }
  }
}

export default ChartMixin;