<template>
  <PageWithLayout :isBodyBg="false">
    <div class="group_box">
      <BoxView title="BarChart">
        <BarChart
          :chartData="barChartData"/>
      </BoxView>
      <BoxView title="BarStackChart">
        <BarChart
          :chartData="barStackChartData"
          :isStacked="true"/>
      </BoxView>
      <BoxView title="LineChart">
        <LineChart
          :chartData="lineChartData"/>
      </BoxView>
      <BoxView title="AreaChart">
        <AreaChart
          :chartData="areaChartData"/>
      </BoxView>
      <BoxView title="PieChart">
        <PieChart
          :chartData="pieChartData"/>
      </BoxView>
      <BoxView title="RadarChart">
        <RadarChart
          :chartData="radarChartData"/>
      </BoxView>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import BarChart from "@lemontree-ai/lemontree-admin-common-front/components/common/chart/BarChart.vue";
import LineChart from "@lemontree-ai/lemontree-admin-common-front/components/common/chart/LineChart.vue"
import AreaChart from "@lemontree-ai/lemontree-admin-common-front/components/common/chart/AreaChart.vue";
import PieChart from "@lemontree-ai/lemontree-admin-common-front/components/common/chart/PieChart.vue";
import RadarChart from "@lemontree-ai/lemontree-admin-common-front/components/common/chart/RadarChart.vue";

export default {
  name:'Chart',
  components:{
    PageWithLayout,
    BoxView,
    BarChart,
    LineChart,
    AreaChart,
    PieChart,
    RadarChart,
  },
  data() {
    return {
      chartData: {
        Books: 24,
        Magazine: 30,
        Newspapers: 10
      },
      barChartData:{
        labels:[ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
        datasets:[
          {
            label: "Data 1",
            backgroundColor: "blue",
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 100, 11]
          },
          {
            label: "Data 2",
            backgroundColor: "green",
            data: [10, 8, 2, 12, 1, 9, 20, 12, 5, 40, 14, 11]
          }
        ]
      },
      barStackChartData:{
        labels:[ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
        datasets:[
          {
            label: "Data 1",
            backgroundColor: "blue",
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 100, 11]
          },
          {
            label: "Data 2",
            backgroundColor: "green",
            data: [10, 8, 2, 12, 1, 9, 20, 12, 5, 40, 14, 11]
          }
        ]
      },
      lineChartData:{
        labels:[ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
        datasets:[
          {
            label: "Data 1",
            backgroundColor: "transparent",
            borderColor: 'blue',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 100, 11],
          },
          {
            label: "Data 2",
            backgroundColor: "transparent",
            borderColor: 'green',
            data: [10, 8, 2, 12, 1, 9, 20, 12, 5, 40, 14, 11],
          }
        ]
      },
      areaChartData:{
        labels:[ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
        datasets:[
          {
            label: "Data 1",
            backgroundColor: "blue",
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 100, 11],
          },
          {
            label: "Data 2",
            backgroundColor: "green",
            data: [10, 8, 2, 12, 1, 9, 20, 12, 5, 40, 14, 11],
          }
        ],
      },
      pieChartData:{
        labels: ["Books", "Magazines", "Newspapers"],
        datasets: [
          {
            backgroundColor: [
              { color: 'primary', isGradient:true },
              { color: 'purple', isGradient:true },
              { color: 'blue', isGradient:false },
            ],
            data: [40, 20, 10]
          }
        ],
      },
      radarChartData:{
        labels: [
          "Eating",
          "Drinking",
          "Sleeping",
          "Designing",
          "Coding",
          "Cycling",
          "Running"
        ],
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: "blue",
            data: [65, 59, 90, 81, 56, 55, 40]
          },
          {
            label: "My Second dataset",
            backgroundColor: "green",
            data: [28, 48, 40, 19, 96, 27, 100]
          }
        ]
      }
    };
  }
}
</script>